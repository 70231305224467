export const EVENTS = [
    // {
    //     name: 'Studio Recital at the Taylor House',
    //     date: '2018-12-09 13:30',
    //     url: 'https://www.facebook.com/events/471715006681348/',
    //     location: ['Taylor House B&B', 'Boston, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Rogerson House Performance',
    //     date: '2019-09-30 16:30',
    //     location: ['Rogerson House', 'Jamaica Plain, MA'],
    //     forParents: false,
    //     url: 'https://www.facebook.com/events/2429679507309227/'
    // },
    // {
    //     name: 'Advanced Group Class',
    //     date: '2019-10-06 11:00',
    //     location: ['Stonybrook Strings', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'JP Lantern Parade Performance',
    //     date: '2019-10-19 17:30',
    //     location: ['Jamaica Pond', 'Jamaica Plain, MA'],
    //     forParents: false,
    //     url: 'https://www.facebook.com/events/776599712797670/'
    // },
    // {
    //     name: 'Advanced Group Class',
    //     date: '2019-11-17 11:00',
    //     location: ['Stonybrook Strings', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Rogerson House Thanksgiving Performance',
    //     date: '2019-11-25 16:15',
    //     location: ['Rogerson House', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Recital Dress Rehearsal',
    //     date: '2019-12-08 10:00',
    //     location: ['Stonybrook Strings', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Studio Recital at the Taylor House',
    //     date: '2019-12-15 14:00',
    //     location: ['Taylor House B&B', 'Boston, MA'],
    //     forParents: false,
    //     url: 'https://www.facebook.com/events/894659994267137/'
    // },
    // {
    //     name: 'Advanced Group Class',
    //     date: '2020-01-12 11:00',
    //     location: ['Stonybrook Strings', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Rogerson House Performance',
    //     date: '2020-01-13 16:15',
    //     location: ['Rogerson House', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: '"Centathlon" Celebration',
    //     date: '2020-02-10 16:30',
    //     location: ['Stonybrook Strings', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Advanced Group Class',
    //     date: '2020-03-01 11:00',
    //     location: ['Stonybrook Strings', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Rogerson House Performance',
    //     date: '2020-03-09 16:15',
    //     location: ['Rogerson House', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Advanced Group Class and Violin Buddy Lessons',
    //     date: '2020-04-05 11:00',
    //     location: ['Stonybrook Strings', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Rogerson House Performance',
    //     date: '2020-04-13 16:15',
    //     location: ['Rogerson House', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Violin Buddy Lesson Day',
    //     date: '2020-05-03 11:00',
    //     location: ['Stonybrook Strings', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Busking Group Class',
    //     date: '2020-05-04 16:30',
    //     location: ['Stonybrook T Stop', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Advanced Group Class',
    //     date: '2020-05-17 11:00',
    //     location: ['Stonybrook Strings', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Rogerson House Performance and Dress Rehearsals',
    //     date: '2020-05-18 16:15',
    //     location: ['Rogerson House', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'Spring Studio Recital',
    //     date: '2020-05-30 11:00',
    //     location: ['JP Public Library', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
    // {
    //     name: 'JP Licks Summer Studio Party',
    //     date: '2020-06-06 12:00',
    //     location: ['JP Licks', 'Jamaica Plain, MA'],
    //     forParents: false
    // },
];
