import moment from 'moment';
import { EVENTS } from 'data/events';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

/**
 * @param {Object} data
 * @returns {Promise}
 */
export function getSortedEvents() {
    return EVENTS.filter(evt => {
        const isTomorrowOrAfter = moment(evt.date, DATE_FORMAT).set({ hour: 0 }).add(1, 'days').diff(moment()) > 0;
        return isTomorrowOrAfter;
    }).sort((a, b) => {
        return moment(a.date, DATE_FORMAT) - moment(b.date, DATE_FORMAT);
    });
}

/**
 * @param {Object} data
 * @returns {Promise}
 */
export function getSortedParentEvents() {
    return getSortedEvents().filter(evt => evt.forParents);
}